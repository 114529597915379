<template>
  <div id="app">
    <Header
      isFixed
      isHyaline
      :hyalineHeight="!isHome ? 60 : 100"
      :scrollHeight="!isHome ? 80 : 140"
    />
    <keep-alive :include="include">
      <!-- 需要缓存的视图组件 -->
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>

    <!-- 不需要缓存的视图组件 -->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <Footer />
  </div>
</template>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  components: {
    Header,
    Footer,
  },
  data: () => ({
    include: [],
    isHome: false,
  }),
  watch: {
    $route(to, from) {
      //如果 要 to(进入) 的页面是需要 keepAlive 缓存的，把 name push 进 include数组
      if (to.meta.keepAlive) {
        !this.include.includes(to.name) && this.include.push(to.name);
      }
      this.isHome = to.fullPath === "/";
      console.log(this.isHome);
    },
  },
  created() {
    this.isHome = this.$route.fullPath === "/";
  },
};
</script>
