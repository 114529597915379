<template>
  <div class="carousel-3d">
    <carousel-3d
      :autoplay="true"
      :autoplayTimeout="3000"
      :perspective="5"
      :display="5"
      :animationSpeed="1000"
      :width="714"
      :height="332"
      controlsVisible
      :controlsWidth="56"
      :controlsHeight="56"
    >
      <slide v-for="(item, i) in list" :index="i" :key="i">
        <template slot-scope="obj">
          <div
            class="slide-img"
            @click="imgClick(item, obj)"
            :style="{ backgroundImage: `url(${item[objKey]})` }"
          ></div>
          <!-- <img :src="item.src" @click="imgClick(item, obj)" /> -->
        </template>
      </slide>
    </carousel-3d>
  </div>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    objKey: {
      type: String,
      default: "icon",
    },
  },
  components: {
    Carousel3d,
    Slide,
  },
  methods: {
    imgClick(data, obj) {
      console.log(data, obj);
      if(data.id==181){
        window.open('https://www.gxyunnonglian.com', '_blank');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss"; // global css
.carousel-3d {
  .slide-img {
    position: absolute;
    top: 0;
    right: 120px;
    bottom: 0;
    left: 120px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .carousel-3d-slide {
    background: none;
    border: none;
    .slide-img {
      opacity: 0.3;
    }
    &.current {
      .slide-img {
        opacity: 1;
        right: 0;
        left: 0;
      }
    }
  }
  ::v-deep .carousel-3d-controls {
    .next,
    .prev {
      font-size: 50px;
      line-height: 50px !important;
      text-align: center;
      color: $main-color;
      background: #fff;
      border-radius: 50%;
      box-shadow: $box-shadow;
    }
  }
}
</style>
