<template>
  <div>
    <div
      class="hearder-warpper"
      :class="{ isFixed, isHyaline, boxShadow: opcatiy > 0.5 }"
      :style="
        isFixed && isHyaline ? `background:rgba(255,255,255,${opcatiy})` : ''
      "
    >
      <div class="page-width-warpper hearder-content">
        <img class="logo" src="@/assets/images/logo.png" />
        <ul>
          <li
            v-for="(item, index) in navList"
            :key="index"
            :class="{ active: currentIndex === index }"
            @click="navSelect(item)"
          >
            {{ item.name }}
            <template v-if="item.children">
              <div class="children">
                <p
                  :class="
                    currentIndex === index && childrenIndex === index2
                      ? 'on'
                      : ''
                  "
                  v-for="(arr, index2) in item.children"
                  :key="index2"
                  @click.stop="$router.push(arr.to)"
                >
                  <span> {{ arr.label }}</span>
                </p>
              </div>
            </template>
          </li>
        </ul>
        <div class="search-box">
          <el-input
            v-model="searchValue"
            placeholder="请输入"
            @keyup.enter.native="search(searchValue)"
          />
          <i class="el-icon-search" @click="search(searchValue)"></i>
        </div>
      </div>
    </div>
    <div class="hearder-height" v-show="isFixed && !isHyaline"></div>
  </div>
</template>

<script>
export default {
  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },
    // 初始透明背景
    isHyaline: {
      type: Boolean,
      default: false,
    },
    hyalineHeight: {
      type: Number,
      default: 140,
    },
    // 渐变距离
    scrollHeight: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      opcatiy: {},
      searchValue: "",
      navList: [
        { name: "首页", to: "/" },
        { name: "关于我们", to: "/about" },
        { name: "公司动态", to: "/news" },
        { name: "互动问答", to: "/interlocution" },
        { name: "业务体系", to: "/system" },
        { name: "联系方式", to: "/contactUs" },
        // {
        //   name: "帮助中心",
        //   type: "link",
        //   flag: true,
        //   children: [
        //     // { to: "/front-bear", label: "行业智库" },
        //     { to: "/about", label: "关于我们" },
        //     { to: "/front-help", label: "帮助中心" },
        //   ],
        // },
      ],
      currentIndex: 0,
      childrenIndex: 0,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        let findIndex = -1;
        // console.log(this.navList)
        this.navList.some((item, index) => {
          if (
            (item.to && item.to === val.path) ||
            (item.children &&
              item.children.findIndex((f) => f.to === val.path) !== -1)
          ) {
            // if (val.fullPath === item.to) {
            findIndex = index;
            return true;
          }
        });
        // console.log(findIndex)
        this.currentIndex = findIndex;
        if (
          this.currentIndex !== -1 &&
          this.navList[this.currentIndex].children
        ) {
          this.childrenIndex = 0;
          if (val.query.code) {
            this.navList[this.currentIndex].children.some((item, index) => {
              if (item.code === val.query.code) {
                this.childrenIndex = index;
                return true;
              }
            });
          }
        } else {
          this.currentIndex = findIndex;
        }
        // if (this.currentIndex == 0) {
        //   this.currentIndex = 4
        // }
      },
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // 处理滚动事件的逻辑
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= this.hyalineHeight) {
        if (scrollTop >= this.hyalineHeight + this.scrollHeight) {
          this.opcatiy = 1;
          return;
        }
        this.opcatiy = (scrollTop - this.hyalineHeight) / this.scrollHeight;
      } else {
        this.opcatiy = 0;
      }
    },
    search(title) {
      this.searchValue = "";
      this.$router.replace(`/news?title=${title}&time=${new Date().getTime()}`);
    },
    navSelect(item) {
      if (item.break) {
        window.open(`${window.location.origin}${item.to}`, "_blank");
      } else if (item.children && item.type === "code") {
        this.$router.push({
          path: item.to,
          query: { code: item.children[0].code },
        });
      } else if (item.children && item.type === "link") {
        this.$router.push(item.children[0].to);
      } else {
        this.$router.push({ path: item.to });
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss"; // global css
.hearder-height {
  height: 102px;
}
.hearder-warpper {
  background: #fff;
  padding-top: 22px;
  box-sizing: border-box;
  &.isFixed {
    position: fixed;
    z-index: 1111;
    top: 0;
    left: 0;
    right: 0;
  }
  &.isHyaline {
    background: none;
  }
  &.boxShadow {
    box-shadow: $box-shadow;
  }
  .hearder-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      height: 60px;
      width: auto;
      display: block;
    }
    .search-box {
      display: flex;
      align-items: center;
      padding: 1px 15px;
      background: #efefef;
      border-radius: 30px;
      .el-icon-search {
        color: $main-color;
        cursor: pointer;
      }
      ::v-deep .el-input__inner {
        padding: 0 5px;
        border: none;
        outline: none;
        background: none;
        height: 30px;
      }
    }
    ul {
      flex: 1;
      display: flex;
      margin-left: 80px;
      padding: 12px 0;
      li {
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #333;
        cursor: pointer;
        position: relative;
        margin-right: 63px;
        &:last-child {
          margin-right: 0;
        }
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          height: 3px;
          background: $main-color;
          width: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        &:hover {
          color: $main-color;
          .children {
            display: block;
          }
          &::after {
            transition: 0.5s width;
            width: 100%;
          }
        }
        &.active {
          // background: $main-color;
          color: $main-color;
          &::after {
            width: 100%;
          }
        }
        .children {
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          // min-width: 90px;
          // text-align: center;
          display: none;
          text-align: center;
          background: #fff;
          z-index: 10;
          border-radius: 4px;
          overflow: hidden;
          padding: 5px 15px;
          opacity: 0.9;
          box-shadow: $box-shadow;
          p {
            line-height: 1.5;
            font-size: 14px;
            color: #333;
            cursor: pointer;
            padding: 5px 0;
            white-space: nowrap;
            // border-bottom: 1px solid #efefef;
            // &:last-child {
            //   border: none;
            // }
            &.on {
              color: $main-color;
            }
            &:hover {
              color: $main-color;
            }
          }
        }
      }
    }
  }
}
</style>
