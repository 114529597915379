<template>
  <div class="title-warpper" :class="{ isWhite }">
    <h2>{{ title }}</h2>
    <p>{{ en }}</p>
  </div>
</template>

<script>
export default {
  props: {
    isWhite: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    en: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss"; // global css

.title-warpper {
  position: relative;
  text-align: center;
  line-height: 1.4;
  h2 {
    color: $main-color;
    font-size: 36px;
  }
  p {
    color: #000;
    font-size: 18px;
  }
  &.isWhite {
    h2,
    p {
      color: white;
    }
    &::after {
      content: "";
      margin-top: 5px;
      margin-left: 50%;
      transform: translateX(-50%);
      display: block;
      height: 3px;
      width: 40px;
      background: white;
    }
  }
}
</style>
