<template>
  <div class="footer-warpper" :class="{ isFixed }">
    <div class="page-width-warpper footer-content">
      <div>
        <div class="mobile">
          <i class="el-icon-phone-outline"></i>4008-000-000
        </div>
        <ul>
          <li
            v-for="(item, index) in navList"
            :key="index"
            @click="navSelect(item)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="qrcode">
        <img src="@/assets/images/home/banner1.jpg" />
        <img src="@/assets/images/home/banner1.jpg" />
      </div>
    </div>
    <div class="page-width-warpper copyright">
      版权所有 © 广西云联产业发展有限公司 桂ICP备09020853号-53
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      platForm: {},
      navList: [
        { name: "首页", to: "/" },
        { name: "关于我们", to: "/about" },
        { name: "公司动态", to: "/news" },
        { name: "互动问答", to: "/interlocution" },
        { name: "业务体系", to: "/system" },
        { name: "联系方式", to: "/contactUs" },
      ],
    };
  },
  created() {},
  methods: {
    navSelect(item) {
      if (item.break) {
        window.open(`${window.location.origin}${item.to}`, "_blank");
      } else {
        this.$router.push({ path: item.to });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss"; // global css
.footer-warpper {
  background: #2e2e32;
  padding: 50px 0 28px;
  &.isFixed {
    position: fixed;
    z-index: 1111;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .footer-content {
    display: flex;
    justify-content: space-between;
    padding-bottom: 32px;
    border-bottom: 1px solid #434347;
    .mobile {
      line-height: 44px;
      font-size: 24px;
      color: #fff;
      font-weight: bold;
      i {
        margin-right: 5px;
      }
    }
    ul {
      display: flex;
      padding: 20px 20px 0 0;
      li {
        margin-right: 42px;
        line-height: 36px;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
        &:hover {
          color: $main-color;
        }
      }
    }
    .qrcode {
      display: flex;
      img {
        width: 100px;
        height: 100px;
        margin-left: 10px;
      }
    }
  }
  .copyright {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #fff;
  }
}
</style>
