import request from "@/utils/request";
// 获取首页信息
export function getIndex() {
  return request({
    url: "/site/getIndex",
    method: "get",
  });
}
// 获取code列表带分页
export function pageByCode(params) {
  return request({
    url: "/site/cms/pageByCode",
    method: "get",
    params,
  });
}
// 详情
export function newsDetail(cmsId) {
  return request({
    url: `/site/cms/${cmsId}`,
    method: "get",
  });
}
// 获取平台相关
export function getPlatform() {
  return request({
    url: "/site/getPlatform",
    method: "get",
  });
}
