import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { keepAlive: true },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/about.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/news",
    name: "News",
    component: () => import("@/views/news.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/interlocution",
    name: "Interlocution",
    component: () => import("@/views/interlocution.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/contactUs",
    name: "ContactUs",
    component: () => import("@/views/contactUs.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/system",
    name: "System",
    component: () => import("@/views/system.vue"),
    meta: { keepAlive: true },
  },
  {
    path: "/newsDetail",
    name: "NewsDetail",
    component: () => import("@/views/newsDetail.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
