<template>
  <div class="home">
    <el-carousel height="601px">
      <el-carousel-item v-for="(item, i) in pageData.banner" :key="i">
        <img class="banner" :src="item.icon" />
      </el-carousel-item>
    </el-carousel>
    <!-- <div class="about-warpper">
      <div class="info-box page-width-warpper">
        <Title title="关于云联" en="ABOUT GUOLIAN" />
        <div class="info" v-if="pageData.about">
          {{ pageData.about.summary }}
          <div class="images">
            <div
              class="image"
              v-for="(item, i) in aboutImgs"
              :key="i"
              :style="{ backgroundImage: `url(${item.src})` }"
            >
              <span>{{ item.text }}</span>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="about-warpper">
      <div class="info-box page-width-warpper">
        <div class="info-left">
          <h2>关于云联</h2>
          <p>
            广西云联产业发展有限公司以互联网大数据为支撑，为相关行业提供农产品和原材料的网上商品交易、商业信息服务和数字技术服务公司致力于互联网、物联网、大数据、云计算、人工智能等新技术与传统产业的深度融合，实现促进传统产业降本增效的价值使命。
          </p>
          <div class="counts">
            <div class="c-item">
              <div class="count">2018<span>/年</span></div>
              成立时间
            </div>
            <div class="c-item">
              <div class="count">30+<span>/人</span></div>
              科研人员
            </div>
            <div class="c-item">
              <div class="count">30+<span>/项</span></div>
              发明专利
            </div>
          </div>
          <div class="info-btn" @click="$router.push('/about')">
            <div style="position: relative">
              了解更多<i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
        <div class="info-right">
          <img class="image" src="@/assets/images/home/about.png" />
        </div>
      </div>
    </div>
    <div class="swiper-warpper">
      <Title title="云联业务体系" en="BUSINESS GROUP" isWhite />
      <div class="page-width-warpper" v-if="pageData.yunGroup">
        <Swiper :list="pageData.yunGroup" />
      </div>
    </div>
    <div class="news-warpper page-width-warpper">
      <div class="news-box">
        <div class="sub-title-warpper">
          <h3>公司动态</h3>
          <p>COMPANY NEWS</p>
        </div>
        <ul class="news-list" v-if="pageData.news">
          <li
            v-for="(item, i) in pageData.news"
            :key="i"
            :class="{ active: newsCurrent === i }"
            @mouseenter="moveNews(i)"
          >
            <img class="image" :src="item.icon" v-show="newsCurrent === i" />
            <div class="info">
              <div class="title">
                <p>
                  {{ item.title }}
                </p>
                <span>{{ item.createTime }}</span>
              </div>
              <div class="text" v-show="newsCurrent === i">
                {{ item.summary }}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="news-box">
        <div class="sub-title-warpper">
          <h3>互动问答</h3>
          <p>INTERACTIVE Q&A</p>
        </div>
        <ul class="question-lsit" v-if="pageData.commonProblem">
          <li v-for="(item, i) in pageData.commonProblem" :key="i">
            <div class="question">{{ item.title }}</div>
            <div class="answer">
              <div class="answer-content" v-html="item.content"></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Title from "./components/Title.vue";
import Swiper from "./components/Swiper.vue";
import { getIndex } from "@/api/index";

export default {
  name: "Home",
  components: {
    Title,
    Swiper,
  },
  data() {
    return {
      aboutImgs: [
        {
          text: "全链路数字化运营，智 慧农产品，区块链溯源",
          src: require("@/assets/images/home/about1.jpg"),
        },
        {
          text: "全链路数字化运营，智 慧农产品，区块链溯源",
          src: require("@/assets/images/home/about2.jpg"),
        },
        {
          text: "全链路数字化运营，智 慧农产品，区块链溯源",
          src: require("@/assets/images/home/about3.jpg"),
        },
      ],
      newsCurrent: 0,
      newsList: [],
      pageData: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    moveNews(i) {
      this.newsCurrent = i;
    },
    getData() {
      getIndex().then((res) => {
        this.pageData = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss"; // global css
@import "@/assets/styles/interlocution.scss";
.home {
  .banner {
    width: 100%;
    height: 601px;
    object-fit: cover;
  }
  .about-warpper {
    background: #f0f6fb;
    padding-bottom: 100px;
    .info-box {
      position: relative;
      z-index: 11;
      border-radius: 2px;
      background: #fff;
      box-shadow: $box-shadow;
      margin-top: -60px;
      // padding: 60px 90px 1px;
      display: flex;
      .info-left {
        flex: 1;
        padding: 60px 0 20px 36px;
        h2 {
          color: $main-color;
          font-size: 36px;
        }
        p {
          margin-top: 40px;
          line-height: 1.6;
          color: #666;
        }
        .counts {
          margin-top: 40px;
          display: flex;
          .c-item {
            color: #666;
            font-size: 14px;
            margin-right: 40px;
            .count {
              font-weight: bold;
              color: $main-color;
              font-size: 28px;
              span {
                margin-left: 6px;
                font-size: 15px;
              }
            }
          }
        }
        .info-btn {
          margin-top: 50px;
          padding: 14px 30px;
          background: $main-color;
          color: #fff;
          border-radius: 6px;
          display: inline-block;
          cursor: pointer;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            // right: 100%;
            right: 0;
            top: 0;
            bottom: 0;
            border-radius: 6px;
            opacity: 0;
            background: red;
            transition: 0.5s all;
            transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
          }
          &:hover {
            &::before {
              opacity: 1;
              // right: 100%;
              // right: 0;
            }
          }
          i {
            margin-left: 10px;
            font-size: 12px;
            color: #fff;
          }
        }
      }
      .info-right {
        width: 61.8%;
        img {
          display: block;
          width: 120%;
          margin-left: -20%;
        }
      }
      .info {
        padding-top: 30px;
        margin-bottom: -80px;
        text-align: center;
        line-height: 1.8;
        font-size: 17px;

        .images {
          display: flex;
          justify-content: center;
          margin-top: 50px;
          .image {
            margin: 0 3px;
            width: 199px;
            height: 251px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            span {
              position: absolute;
              bottom: 20px;
              left: 20px;
              right: 20px;
              font-size: 14px;
              color: #fff;
              line-height: 1.6;
              text-align: left;
            }
          }
        }
      }
    }
  }
  .swiper-warpper {
    position: relative;
    padding-top: 66px;
    padding-bottom: 54px;
    background: url(../../assets/images/home/swiper-back.jpg) center center
      no-repeat;
    background-size: cover;
    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   background: #273e60;
    //   height: 370px;
    // }
    .page-width-warpper {
      margin-top: 40px;
    }
  }
  .news-warpper {
    display: flex;
    padding-top: 55px;
    padding-bottom: 48px;
    .news-box {
      flex: 1;
      &:nth-child(2) {
        margin-left: 50px;
      }
      .sub-title-warpper {
        border-left: 2px solid $main-color;
        line-height: 1.4;
        padding-left: 20px;
        margin-bottom: 28px;
        h3 {
          color: $main-color;
          font-size: 26px;
          font-weight: normal;
        }
        p {
          font-size: 12px;
        }
      }
      .news-list {
        li {
          display: flex;
          cursor: pointer;
          &.active {
            border-bottom: 1px solid #eee;
            margin-bottom: 10px;
            padding-bottom: 20px;
            border-top: 1px solid #eee;
            margin-top: 10px;
            padding-top: 10px;
            &:first-child {
              border-top: none;
              margin-top: 0;
              padding-top: 0;
            }
          }
          .image {
            width: 176px;
            height: 116px;
            margin-right: 15px;
            object-fit: cover;
            border-radius: 2px;
          }
          .info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .title {
            display: flex;
            line-height: 30px;
            p {
              margin-right: 20px;
              font-size: 15px;
              color: #000;
              flex: 1;
              @include clamp(1);
            }
            span {
              font-size: 12px;
              color: #999;
            }
          }
          .text {
            display: none;
            margin-bottom: 5px;
            line-height: 1.6;
            font-size: 14px;
            color: #666;
            @include clamp(3);
          }
        }
      }
    }
  }
}
</style>
