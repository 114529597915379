import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import plugins from "./plugins"; // plugins
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// 分页组件
import Pagination from "@/components/Pagination";
import BaiduMap from "vue-baidu-map";

import "@/assets/styles/index.scss"; // global css

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(plugins);
Vue.component("Pagination", Pagination);
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "cCQAO2D1N7ABtOra2H5TtyjG40ZwKqKG",
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
